<template>
  <div>
    <h1>
      Jukebox - Intervalles et degrés relatifs au service de l'analyse musicale
    </h1>
    <iframe
      src="static/frames/jukebox/index.html"
      width="1040"
      height="700"
      frameborder="0"
      scrolling="no"
    />
  </div>
</template>

<script>
export default {};
</script>
